export const subjects: Record<string, string> = {
  "m": "математика",
  "r": "русский язык",
  "i": "информатика",
  "f": "физика",
  "e": "иностранный язык",
  "o": "обществознание",
  "c": "химия",
  "b": "биология",
},
  ids: Record<string, string> = {
    "математика": "m",
    "русский язык": "r",
    "информатика": "i",
    "физика": "f",
    "иностранный язык": "e",
    "обществознание": "o",
    "химия": "c",
    "биология": "b",
  },
  ia = "ИД",
  bvi = "БВИ",
  sto = "100",
  wtf = "—",
  itin = "спросить у Итина",
  confPoints = 75,
  physics = ["12.03.03", "12.03.05", "16.03.01"],
  chemistry = ["05.03.06", "12.03.04", "18.03.01", "18.03.02", "19.03.01"],
  social = ["27.03.05", "38.03.05"],
  informatics = ["09.03.01", "09.03.03", "09.03.04", "10.03.01", "11.03.02", "11.03.03", "12.03.01",
    "13.03.02", "15.03.04", "15.03.06", "24.03.02", "27.03.04"],
  olympSubjBy: Record<string, boolean | string | Record<string, string[]>> = {
    "автоматизация бизнес-процессов": {
      "информатика": informatics,
      "обществознание": social,
    },
    "автономные транспортные системы": "информатика",
    "академический рисунок, живопись, композиция, история искусства и культуры": "информатика",
    "анализ космических снимков и геопространственных данных": "физика",
    "английский язык": false, // рсош 2021
    "архитектура, изобразительные и прикладные виды искусств": "информатика", // 2022
    "астрономия и науки о земле": "физика",
    "астрономия": "физика",
    "аэрокосмические системы": "информатика",
    "беспилотные авиационные системы": "информатика",
    "биология": "биология",
    "водные робототехнические системы": "информатика",
    "восточные языки": "иностранный язык",
    "генетика": "биология", // 2022
    "геномное редактирование": "биология", // 2022
    "графика": "информатика",
    "графический дизайн": "информатика",
    "гуманитарные и социальные науки": "обществознание",
    "дизайн": "информатика",
    "естественные науки": {
      "химия": chemistry,
      "физика": physics,
      "обществознание": social,
    },
    "журналистика": false,
    "инженерное дело": {
      "физика": physics,
      "информатика": informatics,
    },
    "инженерные биологические системы: агробиотехнологии": "биология",
    "инженерные биологические системы": "биология",
    "инженерные науки": {
      "физика": physics,
      "информатика": informatics,
    },
    "инженерные системы": {
      "физика": physics,
      "информатика": informatics,
    },
    "иностранные языки": "иностранный язык",
    "иностранный язык": "иностранный язык",
    "интеллектуальные робототехнические системы": "информатика",
    "интеллектуальные энергетические системы": "информатика",
    "информатика": "информатика",
    "информационная безопасность": "информатика",
    "информационные и коммуникационные технологии": "информатика",
    "информационные технологии": "информатика",
    "искусственный интеллект": "информатика",
    "искусство, черчение": "информатика",
    "китайский язык": "иностранный язык",
    "комплекс предметов (физика, информатика, математика)": "физика",
    "компьютерная безопасность": "информатика", // 2022
    "компьютерное моделирование и графика": "информатика",
    "космонавтика": "физика", // 2022
    "криптография": "математика",
    "летающая робототехника": "информатика",
    "лингвистика": "иностранный язык",
    "математика": "математика",
    "медицина": "биология",
    "механика и математическое моделирование": "физика",
    "наносистемы и наноинженерия": {
      "физика": physics,
      "химия": chemistry,
    },
    "нанотехнологии": {
      "физика": physics,
      "химия": chemistry,
    },
    "нейротехнологии и когнитивные науки": "информатика",
    "нейротехнологии": "информатика", // 2022
    "обществознание": "обществознание",
    "педагогические науки и образование": false,
    "передовые производственные технологии": "информатика",
    "политология": "обществознание",
    "право": "обществознание", // 2022
    "программирование": "информатика",
    "предпрофессиональная": "физика", // 2022
    "программная инженерия финансовых технологий": "информатика",
    "разработка приложений виртуальной и дополненной реальности": "информатика",
    "рисунок, живопись, скульптура, дизайн": "информатика",
    "рисунок": "информатика",
    "робототехника": "информатика", // 2022
    "русский язык": "русский язык",
    "системы связи и дистанционного зондирования земли": "информатика", // 2022
    "социология": "обществознание",
    "спутниковые системы": "информатика",
    "техника и технологии": "физика",
    "технический рисунок и декоративная композиция": "информатика",
    "технологии беспроводной связи": "информатика",
    "умный город": "информатика",
    "физика": "физика",
    "филология": false,
    "финансовая грамотность": "обществознание",
    "химия": "химия",
    "экология": "биология", // 2022
    "экономика": "обществознание",
    "электроника и вычислительная техника": "информатика", // 2022
    "электронная инженерия: умный дом": "информатика",
    "ядерные технологии": "физика",
  },
  RSROLYMP = "https://diploma.rsr-olymp.ru/files/rsosh-diplomas-static/compiled-storage-",
  WLS = window.location.search,
  fromWLS = WLS.includes("LN=");
